<template>
  <div class="mine">
    <mt-header :title="$t('mine')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="bar">
      <img src="@/assets/accountLogo.png">
      <div class="name">{{ user.name }}</div>
      <div class="phone">{{ user.phone }}</div>
    </div>
    <div class="content">
      <!-- <mt-cell :title="$t('personalInfo')" is-link to="/mine/personalInfo"></mt-cell> -->
      <mt-cell :title="$t('orderHistory')" is-link to="/mine/orderHistory"></mt-cell>
      <mt-cell :title="$t('repaymentInfo')" is-link to="/mine/repayRecord"></mt-cell>
      <!-- <mt-cell :title="$t('inviteFriends')" is-link to="/mine/inviteFriends"></mt-cell> -->
      <!-- <mt-cell :title="$t('discountCoupon')" is-link to="/mine/discountCoupon"></mt-cell> -->
      <!-- <mt-cell :title="$t('myPromotion')" is-link to="/mine/myPromotion"></mt-cell> -->
    </div>
    <div class="bottom">
      <mt-button type="danger" size="large" @click="onLogOut">{{ $t('logOut') }}</mt-button>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { mapMutations, mapState } from "vuex";
export default {
  name: 'Mine',
  components: {
    callPhone
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["SET_ACCESS_TOKEN", "SET_USER"]),
    onLogOut() {
      this.SET_ACCESS_TOKEN();
      this.SET_USER({});
      this.$router.push('/user/login');
    }
  }
}
</script>

<style lang="scss" scoped>
.mine {
  .bar {
    text-align: center;
    color: white;
    font-size: 13px;
    padding-bottom: 20px;
    background: linear-gradient(-78deg, #C82090 0%, #7E16C3 48%, #6A14D1 100%);
    img {
      height: 83px;
      margin-top: 20px;
    }
  }
  .content {
    padding-bottom: 30px;
  }
  .bottom {
    padding: 20px;
  }
}
</style>
